<template>
  <div v-if="page_loading_full">
    <loading :page_loading_full="page_loading_full" />
  </div>
  <div
    style="min-height: 100vh; max-width: 100%; background-color: white"
    v-else
    class="mt-0"
    align="center"
  >
    <v-card :elevation="0" width="1300">
      <v-card-title class="pb-0">
        <v-row>
          <v-col cols="6" align="left">
            <img
              v-if="!branch.isSubscription"
              src="@/assets/logo_color_packhai_warehouse.png"
              style="width: 170px"
            />
            <img v-else :src="branch.logoAWB" width="170px" />
          </v-col>
          <v-col cols="6" align="right">
            <span class="fn-20"
              ><b> {{ importExportMaster.text }} </b></span
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6" class="fn-12" align="left">
            <div>{{ AddressInvoice.name }}</div>
            <div v-if="AddressInvoice.idcardNumber != null">
              เลขประจำตัวผู้เสียภาษี : {{ AddressInvoice.idcardNumber }}
            </div>
            <div>ที่อยู่ : {{ AddressInvoice.address }}</div>
            <div>
              ตำบล {{ AddressInvoice.subdistrict }} อำเภอ
              {{ AddressInvoice.district }} จังหวัด {{ AddressInvoice.province }}
              {{ AddressInvoice.postcode }}
            </div>
          </v-col>
          <v-col cols="6" align="right">
            <span class="fn-20"> เลขที่ : {{ GenIE(importExportMaster.id) }}</span>
            <v-row justify="end">
              <div class="mr-1">
                <VueBarcode
                  :value="importExportMaster.idtext"
                  height="30"
                  width="2"
                  fontSize="0"
                  marginTop="10"
                  marginBottom="5"
                />
              </div>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="fn-12" align="left">
            วันที่ : {{ GetDateTime() }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="fn-12" align="left">
            <span v-if="shop != null"> ร้านค้า : {{ shop.name }} </span>
          </v-col>
          <v-col cols="6" align="right" class="fn-12">
            <span v-if="warehouse != null"> คลัง : {{ warehouse.name }} </span>
          </v-col>
        </v-row>

        <div class="pt-1" align="left">
          <v-data-table
            :headers="header"
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-footer
            :mobile-breakpoint="0"
            class="packhai-border-table-print-importstock"
          >
            <template v-slot:item.no="{ item }">
              {{ formatMoney(dataset.indexOf(item) + 1) }}
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }} {{ item.prop1Description }} {{ item.prop2Description }}
            </template>
            <template v-slot:item.quantity="{ item }">
              {{ formatMoney(item.quantity) }}
            </template>
            <template v-slot:item.sku="{ item }">
              {{ item.sku }}
            </template>
            <template v-slot:item.barcode="{ item }">
              {{ item.barcode }}
            </template>
            <template v-slot:item.manufactureDateNow="{ item }">
              {{ item.manufactureDateNow }}
            </template>
            <template v-slot:item.expirationDateNow="{ item }">
              {{ item.expirationDateNow }}
            </template>

            <template v-slot:item.remark="{ item }">
              {{ item.remark }}
            </template>
          </v-data-table>
        </div>

        <v-row class="pt-3">
          <v-col cols="12" class="fn-12" align="left">
            <b> หมายเหตุ : {{ importExportMaster.remark }} </b>
          </v-col>
        </v-row>

        <v-row style="padding-top: 60px">
          <v-col cols="4" align="center" class="fn-12">
            -------------------------------------------
            <br />
            ผู้ตรวจเช็คสินค้า
          </v-col>
          <v-col cols="4" align="center" class="fn-12">
            -------------------------------------------
            <br />
            ผู้ส่งสินค้า
          </v-col>
          <v-col cols="4" align="center" class="fn-12">
            -------------------------------------------
            <br />
            วันที่เวลา
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "@/website/components/Loading";
import {
  stockService_dotnet,
  shopService_dotnet,
  branchService_dotnet,
  orderService_dotnet,
  generalService_dotnet,
} from "@/website/global";
import {
  GenIE,
  GetDateTime,
  formatMoney,
  formatDate_thai,
} from "@/website/global_function";
import VueBarcode from "vue-barcode";
export default {
  components: {
    Loading,
    VueBarcode,
  },
  data: () => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    page_loading_full: true,
    postcode_mappingList: null,
    postcode_mapping: null,
    AddressInvoice: {
      name: null,
      idcardNumber: null,
      phone: null,
      address: null,
      subdistrict: null,
      district: null,
      province: null,
      postcode: null,
    },
    branch: null,
    idcardNumber: null,
    shop: null,
    warehouse: null,
    importExportMaster: {
      text: "",
      branchId: parseInt(localStorage.getItem("Branch_BranchID")),
      shopId: 0,
      staffBranchId: parseInt(localStorage.getItem("Branch_StaffID")),
      staffShopId: 0,
      id: 0,
      approvedStaffBranchId: 0,
      approvedStaffBranchName: null,
      approvedStaffShopId: 0,
      approvedStaffShopName: null,
      createdDatetime: null,
      isActive: true,
      remark: null,
      returnPrice: null,
      shopName: null,
      referenceNumber: null,
      referenceInfo: null,

      staffBranchName: null,
      staffShopName: null,
      fromWarehouseId: 0,
      fromWarehouseName: null,
      toWarehouseId: 0,
      toWarehouseName: null,
      transactionName: null,
      transactionType: 0,
    },
    header: [
      { text: "No", align: "center", sortable: false, value: "no" },
      { text: "รายการ", align: "left", sortable: false, value: "name" },
      // { text: 'รายการ',align: 'left',sortable: false,value: 'name', width: '500px' },
      { text: "จำนวน", align: "center", sortable: false, value: "quantity" },
      { text: "รหัสสินค้า", align: "left", sortable: false, value: "productCode" },
      { text: "SKU", align: "left", sortable: false, value: "sku" },
      { text: "Barcode", align: "left", sortable: false, value: "barcode" },
      {
        text: "วันที่ผลิต",
        align: "center",
        sortable: false,
        value: "manufactureDateNow",
      },
      {
        text: "วันที่หมดอายุ",
        align: "center",
        sortable: false,
        value: "expirationDateNow",
      },

      { text: "หมายเหตุ", align: "left", sortable: false, value: "remark" },
    ],
    dataset: [],
  }),
  async created() {
    this.importExportMaster.id = parseInt(atob(this.$route.query.id));
    await this.Statup();
    this.page_loading_full = false;
    setTimeout(function () {
      print();
    }, 500);
  },

  methods: {
    async Statup() {
      await axios
        .all([
          axios.post(
            branchService_dotnet + "Branch/get-branch-single",
            {
              branchId: localStorage.getItem("Branch_BranchID"),
            },
            { headers: this.header_token }
          ),

          axios.post(
            branchService_dotnet + "Branch/get-branch-address-invoice-list",
            {
              branchId: localStorage.getItem("Branch_BranchID"),
            },
            { headers: this.header_token }
          ),

          axios.post(
            stockService_dotnet + "Stock/get-importexportmaster",
            {
              branchId: localStorage.getItem("Branch_BranchID"),
              importExportMasterId: this.importExportMaster.id,
            },
            { headers: this.header_token }
          ),

          axios.post(
            stockService_dotnet + "Stock/get-importexportdetail-list",
            {
              branchId: localStorage.getItem("Branch_BranchID"),
              importExportMasterId: this.importExportMaster.id,
            },
            { headers: this.header_token }
          ),

          axios.post(
            stockService_dotnet + "Stock/get-importexportphoto-list",
            {
              importExportMasterId: this.importExportMaster.id,
            },
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread(
            (
              branch,
              addressinvoiceList,
              importExportMaster,
              importExportDetailList,
              importexportPhotoList
            ) => {
              if (
                branch.status == 200 &&
                addressinvoiceList.status == 200 &&
                importExportMaster.status == 200 &&
                importExportDetailList.status == 200 &&
                importexportPhotoList.status == 200
              ) {
                this.branch = branch.data.branch;
                addressinvoiceList = addressinvoiceList.data;
                importExportMaster = importExportMaster.data;
                importExportDetailList = importExportDetailList.data;
                importexportPhotoList = importexportPhotoList.data;

                if (addressinvoiceList.length > 0) {
                  const defaultAddress = addressinvoiceList.filter((item) => {
                    return item.isDefault == true;
                  });
                  if (defaultAddress.length > 0) {
                    this.AddressInvoice.name = defaultAddress[0].name;
                    this.AddressInvoice.idcardNumber = defaultAddress[0].idCardNumber;
                    this.AddressInvoice.phone = defaultAddress[0].phone;
                    this.AddressInvoice.address = defaultAddress[0].address;
                    this.AddressInvoice.subdistrict = defaultAddress[0].subDistrict;
                    this.AddressInvoice.district = defaultAddress[0].district;
                    this.AddressInvoice.province = defaultAddress[0].province;
                    this.AddressInvoice.postcode = defaultAddress[0].postcode;
                  }
                }
                if (this.AddressInvoice.name == null) {
                  this.AddressInvoice.name = this.branch.name;
                  this.AddressInvoice.phone = this.branch.phoneNumber;
                  this.AddressInvoice.address = this.branch.address;
                  this.AddressInvoice.subdistrict = this.branch.subDistrict;
                  this.AddressInvoice.district = this.branch.district;
                  this.AddressInvoice.province = this.branch.province;
                  this.AddressInvoice.postcode = this.branch.postcode;
                }
                // set postcodeMapping

                //add idcardNumber
                if (addressinvoiceList.length > 0) {
                  this.idcardNumber = addressinvoiceList[0].idcardNumber;
                }
                this.warehouse = {};
                if (importExportMaster.transactionType == 1) {
                  this.importExportMaster.text = "ใบนำสินค้าเข้าคลัง";
                  this.warehouse.name = importExportMaster.toWarehouseName;
                  document.title = "ใบนำสินค้าเข้าเลขที่_" + GenIE(importExportMaster.id);
                } else if (importExportMaster.transactionType == 2) {
                  this.importExportMaster.text = "ใบส่งออกสินค้า";
                  this.warehouse.name = importExportMaster.fromWarehouseName;
                  document.title = "ใบส่งออกสินค้าเลขที่_" + GenIE(importExportMaster.id);
                }
                this.importExportMaster.idtext = GenIE(importExportMaster.id);
                this.importExportMaster.shopId =
                  importExportMaster.shopId == null ? 0 : importExportMaster.shopId;
                this.importExportMaster.staffShopId =
                  importExportMaster.staffShopId == null
                    ? 0
                    : importExportMaster.staffShopId;
                this.importExportMaster.staffShopName = importExportMaster.staffShopName;
                this.importExportMaster.staffBranchId =
                  importExportMaster.staffBranchId == null
                    ? 0
                    : importExportMaster.staffBranchId;
                this.importExportMaster.staffBranchName =
                  importExportMaster.staffBranchName;
                this.importExportMaster.approvedDatetime =
                  importExportMaster.approvedDatetime;
                this.importExportMaster.approvedStaffBranchId =
                  importExportMaster.approvedStaffBranchId == null
                    ? 0
                    : importExportMaster.approvedStaffBranchId;
                this.importExportMaster.approvedStaffBranchName =
                  importExportMaster.approvedStaffBranchName;
                this.importExportMaster.approvedStaffShopId =
                  importExportMaster.approvedStaffShopId == null
                    ? 0
                    : importExportMaster.approvedStaffShopId;
                this.importExportMaster.approvedStaffShopName =
                  importExportMaster.approvedStaffShopName;
                this.importExportMaster.createdDatetime =
                  importExportMaster.createdDatetime;
                this.importExportMaster.isActive =
                  importExportMaster.isActive == 0 ? false : true;
                this.importExportMaster.remark = importExportMaster.remark;
                this.importExportMaster.returnPrice =
                  importExportMaster.returnPrice == null
                    ? 0
                    : importExportMaster.returnPrice;
                this.importExportMaster.shopName = importExportMaster.shopName;
                this.importExportMaster.staffBranchName =
                  importExportMaster.staffBranchName;
                this.importExportMaster.transactionName =
                  importExportMaster.transactionName;
                this.importExportMaster.transactionType =
                  importExportMaster.transactionType == null
                    ? 0
                    : importExportMaster.transactionType;
                this.importExportMaster.fromWarehouseName =
                  importExportMaster.fromWarehouseName;
                this.importExportMaster.fromWarehouseId =
                  importExportMaster.fromWarehouseId == null
                    ? 0
                    : importExportMaster.fromWarehouseId;
                this.importExportMaster.toWarehouseName =
                  importExportMaster.toWarehouseName;
                this.importExportMaster.toWarehouseId =
                  importExportMaster.toWarehouseId == null
                    ? 0
                    : importExportMaster.toWarehouseId;
                this.importExportMaster.referenceNumber =
                  importExportMaster.referenceNumber;
                this.importExportMaster.referenceInfo = importExportMaster.referenceInfo;
                this.shop = {};
                this.shop.name = importExportMaster.shopName;

                // รายการสินค้า
                this.SetDatasetProduct(importExportDetailList);
              }
            }
          )
        );
    },
    async SetDatasetProduct(data) {
      for (var i of data) {
        var Temp = {
          id: i.productMasterItemId,
          importExportMasterId: i.id,
          importExportDetailId: i.importExportDetailId,
          productMasterId: i.productMasterId,
          productMasterItemId: i.productMasterItemId,
          stockShopId: i.stockShopId,
          stockShopItemId: i.stockShopItemId,
          photoLink: i.photoLink,
          name: i.name,
          prop1: i.prop1,
          prop1Description: i.prop1Description,
          prop2: i.prop2,
          prop2Description: i.prop2Description,
          productCode: i.productCode,
          sku: i.sku,
          barcode: i.barcode,
          costPrice: i.costPrice,
          quantityLeft: i.quantityLeft,
          rackNo: i.receiveRackNo,
          remark: i.itemRemark,
          createdDatetime: i.createdDatetime,
          manufactureDateNow: i.mfgDate == null ? null : i.mfgDate,
          manufactureDateStart:
            i.mfgDate == null ? null : this.parseDate(this.formatDate_thai(i.mfgDate)),
          manufactureDateMenu: false,
          expirationDateNow: i.expiryDate == null ? null : i.expiryDate,
          expirationDateStart:
            i.expiryDate == null
              ? null
              : this.parseDate(this.formatDate_thai(i.expiryDate)),
          expirationDateMenu: false,
          unitPrice: 0,
          quantity: i.quantity,
          quantitySendback: i.quantitySendback,
          discount: i.discount,
        };

        if (Temp.manufactureDateStart != null) {
          Temp.manufactureDateNow = this.formatDate(Temp.manufactureDateStart);
        }
        if (Temp.expirationDateStart != null) {
          Temp.expirationDateNow = this.formatDate(Temp.expirationDateStart);
        }

        this.dataset.push(Temp);
      }
    },
    SearchPostcodeMapping(postcodeMappingId) {
      if (postcodeMappingId == null) {
        return null;
      }
      for (var i in this.postcode_mappingList) {
        if (parseInt(this.postcode_mappingList[i].Id) == parseInt(postcodeMappingId)) {
          return this.postcode_mappingList[i];
        }
      }
      return null;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async OrderBy(data) {
      var result = [];
      for (var i = data.length - 1; i >= 0; i--) {
        result.push(data[i]);
      }
      return result;
    },
    GenIE,
    GetDateTime,
    formatMoney,
    formatDate_thai,
  },
};
</script>

<style scoped>
>>> .vue-barcode-element {
  width: 100% !important;
}
</style>
